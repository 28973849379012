import { Option } from 'types/options';

import { FilterValues, MainBlockValues as Values } from 'components/modules/public/layout/forms/SearchForm';

import Component from './component';

export interface FilterOption {
    name: string;
    slug: string;
    title: string;
}
export interface Props {
    formik: any;
    input: {
        cvFree: {
            label: string;
        }
    }
    filterOptions: {
        options: FilterOption[];
    };

    currentFilterOption: FilterOption;
    setCurrentFilterOption: (nextFilterOption: FilterOption) => any;

    searchParams: any;
    onChangeSearchParam: (key: string, value: any) => any;
}

Component.defaultProps = {};

export default Component;