import { FunctionComponent, useState } from 'react';
import Select from 'react-select';
import classnames from 'classnames';

import { Option } from 'types/options';

import InputWrapper, { ValidationActions } from 'components/layout/forms/InputWrapper';

import { Props } from './index';
import StyledComponent from './styles';

const LayoutFormsSelect: FunctionComponent<Props> = ({ formik, name, label, placeholder, helper, value, onChange, disabled, style, styles, options, validationAction, decoratorLeft, decoratorRight, inputProps }) => {
    const error = formik?.errors[name]?.toString();

    if(options.length > 0 && (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean')) {
        const selectedOption = options.find((option: Option<any>) => option.value === value);
        value = selectedOption || value;
    }
    const [isFocused, setIsFocused]: [boolean, Function] = useState(false);
    return (
        <StyledComponent
            className={classnames(
                'layout-forms-select',
                [
                    `style-${style}`,
                    `name-${name}`,
                ],
                {
                    'error': Boolean(error),
                    'disabled': Boolean(disabled),
                    'decorator-left': Boolean(
                        error && validationAction === ValidationActions.Decorator ||
                        decoratorLeft && decoratorLeft.visible !== false
                    ),
                    'decorator-right': Boolean(decoratorRight && decoratorRight.visible !== false),
                }
            )}
            style={styles}
        >
            <InputWrapper
                name={name}
                label={label}
                helper={helper}
                error={error}
                validationAction={validationAction}
                decoratorLeft={decoratorLeft}
                decoratorRight={decoratorRight}
                isFocused={isFocused}
            >
                <Select
                    classNamePrefix={`select-${name}`}
                    name={name}
                    options={options}
                    value={value}
                    aria-label={label}
                    placeholder={placeholder}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    onChange={(option: Option<any>) => {
                        formik?.setFieldError(name, null);
                        onChange(option);
                    }}
                    isDisabled={disabled}
                    noOptionsMessage={() => 'Brak wyników'}
                    isClearable={true}
                    id={name}
                    instanceId={name}
                    {...inputProps}
                />
            </InputWrapper>
        </StyledComponent>
    );
};

export default LayoutFormsSelect;