import { FunctionComponent, useEffect, useState } from 'react';
import Slider from 'react-slick';
import classnames from 'classnames';

import { Locales } from 'types/locale';

import { isSafari } from 'utils/browser';
import { select as selectLocale } from 'utils/locale';

import arrowLeft from 'theme/icons/arrow-left.svg';
import arrowRight from 'theme/icons/arrow-right.svg';
import bullet from 'theme/icons/bullet.svg';

import SlickButton from 'components/layout/SlickButtons/component';

import { Props } from './index';
import StyledComponent from './styles';

const LayoutSlick: FunctionComponent<Props> = ({ children, sliderProps, reference }) => {
    const [isSafariBrowser, setIsSafariBrowser]: [boolean, Function] = useState(false);
    const translations = selectLocale({
        [Locales.Pl]: require('./locales/pl.json'),
    });

    useEffect(() => {
        setIsSafariBrowser(isSafari());
    }, []);

    return (
        <StyledComponent className={classnames('layout-slick', {
            'safari-view': isSafariBrowser,
        } )}
        >
            <Slider
                adaptiveHeight={false}
                accessibility={true}
                arrows={true}
                dots={true}
                infinite={(children as React.ReactNode[]).length > 1 ? true : false}
                speed={500}
                {...sliderProps}
                nextArrow={(
                    <SlickButton>
                        <img
                            className="arrow-right"
                            src={arrowRight}
                            aria-label={translations.arrowNext}
                            loading="lazy"
                        />
                    </SlickButton>
                )}
                prevArrow={(
                    <SlickButton>
                        <img
                            className="arrow-left"
                            src={arrowLeft}
                            aria-label={translations.arrowPrev}
                            loading="lazy"
                        />
                    </SlickButton>
                )}
                customPaging={(index) => (
                    <img
                        className="icon"
                        src={bullet}
                        aria-label={`${translations.page} ${index + 1}`}
                        loading="lazy"
                    />
                )}
                ref={reference}
            >
                {children}
            </Slider>
        </StyledComponent>
    );
};

export default LayoutSlick;