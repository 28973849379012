import Article from 'models/Article';

import Component from './component';

export interface Props {
    article: Article;
    iconsAlts?: {
        play?: string;
    };
}

Component.defaultProps = {};

export default Component;