import { FunctionComponent } from 'react';
import classnames from 'classnames';

import Checkbox from 'components/layout/forms/Checkbox';

import { Props } from './index';
import StyledComponent from './styles';

const PublicLayoutFormsSearchFormBlocksFilterTabs: FunctionComponent<Props> = ({
    input,
    filterOptions,
    currentFilterOption,
    setCurrentFilterOption,

    searchParams,
    onChangeSearchParam,
}) => {
    return (
        <StyledComponent className="public-layout-forms-search-form-blocks-filter-tabs" >
            <div className="filter-options">
                <div className="filter-header">
                    <div
                        className="header-title"
                        dangerouslySetInnerHTML={{ __html: 'Filtruj według:' }}
                    />
                    <Checkbox
                        name="cvFree"
                        label={input?.cvFree?.label}
                        onChange={() => {
                            if (searchParams.cvFree.getValue() === true) {
                                onChangeSearchParam('cvFree', null);
                            } else {
                                onChangeSearchParam('cvFree', true);
                            }
                        }}
                        value={searchParams.cvFree.getValue()}
                    />
                </div>
                <div className="options">
                    {Array.isArray(filterOptions?.options) && filterOptions?.options.length > 0 && filterOptions?.options
                        .map( (element, index) => {
                            return (
                                <div
                                    key={index}
                                    className={classnames(
                                        'option-container',
                                        { active: element?.slug === currentFilterOption?.slug }
                                    )}
                                    onClick={() => setCurrentFilterOption(element)}
                                >
                                    {Array.isArray(searchParams[element.slug].getValue() )
                                        ? (
                                            <div
                                                className="option"
                                                dangerouslySetInnerHTML={{ __html: `${element?.name} (${searchParams[element.slug].getValue().length})` }}
                                            />
                                        )
                                        : (
                                            <div
                                                className="option"
                                                dangerouslySetInnerHTML={{ __html: `${element?.name} ${searchParams.salary.getValue() ? '(1)' : ''}` }}
                                            />
                                        )
                                    }
                                    <div className="icon-container">
                                        <img
                                            className="icon"
                                            src={element?.slug === currentFilterOption?.slug ? '/images/icons/minus-white.svg' : '/images/icons/plus-white.svg'}
                                            loading="lazy"
                                            alt={element?.name}
                                        />
                                        <img
                                            className="icon-hover"
                                            src={element?.slug === currentFilterOption?.slug ? '/images/icons/minus-black.svg' : '/images/icons/plus-black.svg'}
                                            loading="lazy"
                                            alt={element?.name}
                                        />
                                    </div>
                                </div>
                            );
                        }
                        )}
                </div>
            </div>
        </StyledComponent>
    );
};

export default PublicLayoutFormsSearchFormBlocksFilterTabs;