import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.div`
    @media all and (max-width: ${vars.tabletL}) {}

    @media all and (max-width: ${vars.mobileM}) {
        width: 100%;

        .layout-forms-form {
            position: relative;
            width: 100%;
            border-radius: 0;
            padding: 2.5em;
            font-size: 130%;
        }
    }
`;