
import { useState } from 'react';

import City from 'models/City';
import JobTime from 'models/JobTime';


export interface ISearchParam {
	slug: string
	value: any
	getValue: () => any
	setValue: (value: any) => void
	setValueFromString: (value: any) => void

}

class SearchParam {
    slug: string;
    value: any;
    defaultValue: any;

    constructor(slug: string) {
        this.slug = slug;
    }

    getValue = () => this.value;
    setValue = (value: any) => this.value = value;
    clearValue = (value: string) => this.value = null;
    clearValues = () => this.value = null;
    getQueryValue = () => this.value;
    getQueryKey = () => this.slug;
    setValueFromString = (value: any) => this.value = value;
}

export class SearchParamValue extends SearchParam implements ISearchParam {
    value = '';

    constructor(slug: string, defaultValue : any = null) {
        super(slug);
        this.value = this.setValueFromString( defaultValue );
    }
}

export class SearchParamValues extends SearchParam implements ISearchParam {
    value = [];

    constructor(slug: string, defaultValue : any = null) {
        super(slug);
        this.value = this.setValueFromString( defaultValue );
    }

    setValueFromString = (value: any) => this.value = value ? value.split(',') : [];

    getQueryValue = () => this.value;

    setValue = (value: any) => {
        if (Array.isArray(value))
            this.value = value;
        else
        if (this.value.includes(value)) {
            this.clearValue(value);
        } else {
            this.value.push(value);
        }
    };

    clearValue = (value: string) => {
        const elementIndex = this.value.indexOf(value);
        this.value.splice(elementIndex, 1);
    };

    clearValues = () => this.value = [];
}

export class SearchParamCity extends SearchParam implements ISearchParam {
    value: City = null;
    getQueryValue = () => this.value?.slug;

    constructor(slug: string, defaultValue : any = null) {
        super(slug);
        this.value = defaultValue;
    }
}

export class SearchParamTime extends SearchParam implements ISearchParam {
    value: JobTime =  null;
    getQueryValue = () => this.value;

    constructor(slug: string, defaultValue : any = null) {
        super(slug);
        this.value = defaultValue;
    }
}

export class SearchParamPage extends SearchParam implements ISearchParam {
    value: number = null;

    constructor(slug: string, defaultValue : any = null) {
        super(slug);
        this.value = defaultValue;
    }

    setValue = (value: any) => this.value = value ? value : 1;

    getQueryValue = () => this.value === 1 ? null : this.value;
}


/**
 * useSearchParams hook
 *
 * @returns { getFilters, getFilterValues, getFilterValue, setFilterValue }
 */
export const useSearchParams = (initialFilters: any) => {
    const [filters, setFilters] = useState(initialFilters);

    const getSearchParams = () => filters;
    const getSearchParamValue = (filterName: string) => filters[filterName].getValue();

    const getSearchParamsQueryValues = () => {
        return Object.assign({},
            ...Object.keys(filters).map(
                filterKey => ({ [ filters[filterKey].slug ]: filters[filterKey].getQueryValue() })
            )
        );
    };

    const setSearchParamValue = (key: string, value: any, clear?: boolean ) => {
        const filter = filters[key];

        if (clear) {
            filter.clearValues();
        } else {
            filter.setValue(value);
        }

        setFilters({
            ...filters,
            [key]: filter,
        });
    };

    const setSearchParamValueFromString = (key: string, value: any) => {
        const filter = filters[key];
        filter.setValueFromString(value);

        setFilters({
            ...filters,
            [key]: filter,
        });
    };

    return {
        getSearchParams, getSearchParamsQueryValues, getSearchParamValue, setSearchParamValue, setSearchParamValueFromString,
    };
};