import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.div`
    width: 100%;

    [class$=container] {
        width: 100%;

        [class$=control] {
            width: 100%;
            height: 3em;
            border: .1em solid white;
            color: ${vars.colorText};
            border-radius: .5em;
            font-size: .9em;

            [class$=placeholder] {
                color: ${vars.colorTextLight};
            }
        }

        [class$=menu] {
            z-index: 99;
        }
    }

    &.style-primary {}

    &.style-secondary {
        [class$=container] {
            width: 100%;

            [class$=control] {
                background: #FFFFFF;
                box-shadow: none;
                height: 3em;
                border-radius: 0;

                [class$=ValueContainer] {
                    [class$=singleValue] {
                        color: #000000;
                    }
                }

                [class$=singleValue] {
                    color: #000000;
                }

                [class$=IndicatorsContainer] {
                    [class$=indicatorSeparator] {
                        display: none;
                    }
                    [class$=indicatorContainer] {
                        color: #000000;
                    }
                }

                [class$=placeholder] {
                    color: #000000;
                }
            }

            [class$=menu] {
                z-index: 99;
                background-color: rgba(255, 255, 255, 0.2);

                [class$=MenuList] {
                    [class$=option] {
                        color: #000000;
                    }
                }
            }
        }

        &.disabled {
            [class$=container] {
                [class$=control] {
                    background-color: #d7e2e4;
                }
            }
        }

        .layout-forms-input-wrapper {
            margin-bottom: 0;
        }
    }

    &.decorator-left {
        [class$=container] {
            [class$=control] {
                padding-left: 2em;
            }
        }
    }
    &.decorator-right {
        [class$=container] {
            [class$=control] {
                padding-right: 2em;
            }
        }
    }
`;
