import { Option } from 'types/options';
import ListCollection from 'types/redux/ListCollection';

import City from 'models/City';
import JobAgreement from 'models/JobAgreement';
import JobCategory from 'models/JobCategory';
import JobExperience from 'models/JobExperience';
import JobLanguage from 'models/JobLanguage';
import JobMode from 'models/JobMode';
import JobTime from 'models/JobTime';

import { Props as ExpandableMenuBlockProps } from 'components/modules/public/layout/forms/SearchForm/blocks/ExpandableMenu';
import { Props as FilterTabsBlockProps } from 'components/modules/public/layout/forms/SearchForm/blocks/FilterTabs';
import { Props as MainBlockProps } from 'components/modules/public/layout/forms/SearchForm/blocks/Main';

import Component from './component';
export interface Props {
    mainBlockProps?: MainBlockProps;
    filterTabsBlockProps?: FilterTabsBlockProps;
    expandableMenuBlockProps?: ExpandableMenuBlockProps;

	jobModes?: JobMode[],
	jobCategories?: JobCategory[],
	jobAgreements?: JobAgreement[],
	jobExperiences?: JobExperience[],
	jobLanguages?: JobLanguage[],
	jobTimes?: JobTime[],
	jobCities?: City[],

    onSearch: () => any;
    onChange: (key: string, value: Option<FilterOptions>|string|boolean) => any;
    values: MainBlockValues;
    tooltipOffset: Object;

    searchParams: any;
    onChangeSearchParam: (key: string, value: any) => void;
    onChangeSearchParams: (searchParams: any) => void;
}

export enum FilterOptions {
    JobCategories = 'category',
    JobAgreements = 'agreement',
    JobModes = 'mode',
    JobExperiences = 'experience',
    JobSalaryFrom = 'salary',
    JobLanguages = 'language',
}
export interface FilterValues {
    jobCategorySlug: string[];
    jobAgreementSlug: string[];
    jobModeSlug: string[];
    jobExperienceSlug: string[];
    jobLanguageSlug: string[];
    salaryFrom: string;
}

export interface MainBlockValues {
    companyCitySearch:  {
        label?: string,
        value?: City;
    },
    rangeRadius: number;
    jobTimeSlug: string;
    name: string;
    cvFree: boolean;
    additionalCheckbox: boolean;
}


Component.defaultProps = {
    mainBlockProps: null,
    filterTabsBlockProps: null,
    expandableMenuBlockProps: null,
    jobCategories: [],
    jobAgreements: [],
    jobModes: [],
    jobExperiences: [],
    jobLanguages: [],
};

export default Component;