import styled from '@emotion/styled';

import variables from 'theme/styles/vars';

export default styled.div`
    position: relative;
    width: 100%;
    border: 0.07em solid #000000;
    background-color: #FFFFFF;

    .link {
        text-decoration: none;

        .image-container {
            position: relative;
            width: 100%;
            height: 18em;
            overflow: hidden;

            .article-image {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                transition: .3s ease-in-out;
                -o-object-fit: cover;
                object-fit: cover;
            }

            .icon {
                position: absolute;
                width: 100%;
                max-width: 3em;
                height: auto;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                z-index: 2;

                &.yellow {
                    max-width: 0;
                    transition: max-width .3s ease-in-out;
                }
            }
        }

        .article-content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: stretch;
            min-height: 18em;
            padding: 1.4em;

            .article-name {
                font-size: 1.05em;
                font-weight: 700;
                color: #000000;
                display: flex;
                flex-direction: column;
                flex: 1;
                .article-title {
                    flex:1;
                    .article-heading {
                        padding-bottom: 1.25em;
                    }
                }
            }

            .article-description {
                font-size: 1.025em;
                color: #000000;
            }

            .article-link {
                align-self: flex-end;
                padding-top: 2em;
                .link {
                    text-decoration: none;
                    color: #000000;
                    font-weight: 700;
                }
            }
        }

        &:hover {
            cursor: pointer;

            .image-container {
                .article-image {
                    transform: scale(1.1);
                }

                .icon {
                    &.yellow {
                        max-width: 3em;
                    }
                }
            }

            .article-content {
                .article-name {
                    text-decoration: underline;
                }

                .article-link {
                    .link {
                        text-decoration: underline;
                    }
                }
            }

        }
    }

    @media all and (max-width: ${variables.mobileM}) {
        .link {
            .image-container {
                height: 20em;
            }

            .article-content {
                min-height: 10em;
                .article-description {
                    display: none;
                }
            }
        }
    }
`;