import { FunctionComponent } from 'react';
import Link from 'next/link';

import { Types } from 'models/Article';

import { imageResizer } from 'utils/image';
import { shorten } from 'utils/string';

import { Props } from './index';
import StyledComponent from './styles';

const PublicLayoutArticlesMediumArticleBox: FunctionComponent<Props> = ({ article, iconsAlts }) => {
    return (
        <StyledComponent className="public-layout-articles-medium-article-box">
            <Link
                href={article.href}
                prefetch={false}
                passHref={true}
            >
                <a
                    href=""
                    className="link"
                >
                    <div >
                        <div className="image-container">
                            {article?.coverImage && (
                                <img
                                    className="article-image"
                                    src={imageResizer(article?.coverImage?.imageUrl, { width: 500 })}
                                    loading="lazy"
                                    alt={article.title}
                                />
                            )}
                            {article?.type !== Types.Default && (
                                <img
                                    className="icon default"
                                    src="/images/icons/play-default.svg"
                                    loading="lazy"
                                    alt={iconsAlts?.play}
                                />
                            )}
                            {article?.type !== Types.Default && (
                                <img
                                    className="icon yellow"
                                    src="/images/icons/play-yellow.svg"
                                    loading="lazy"
                                    alt={iconsAlts?.play}
                                />
                            )}
                        </div>
                        <div className="article-content">
                            <div className="article-name">
                                <div className="article-title">
                                    <h3
                                        className="article-heading"
                                        dangerouslySetInnerHTML={{ __html: article?.title }}
                                    />
                                </div>
                            </div>
                            {article?.lead && (
                                <div
                                    className="article-description"
                                    dangerouslySetInnerHTML={{ __html: shorten(article?.lead, 135, true) }}
                                />
                            )}
                            <div className="article-link">
                                <Link
                                    href={article.href}
                                    prefetch={false}
                                >
                                    <a className="link">
                                    Przeczytaj artykuł
                                    </a>
                                </Link>
                            </div>
                        </div>
                    </div>
                </a>
            </Link>
        </StyledComponent>
    );
};

export default PublicLayoutArticlesMediumArticleBox;