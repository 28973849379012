import { ReactNode } from 'react';

import { Option } from 'types/options';

import JobTime from 'models/JobTime';

import { Props as ButtonProps } from 'components/layout/Button';
import { MainBlockValues } from 'components/modules/public/layout/forms/SearchForm';

import Component from './component';

export interface Props extends ParentProps {
    jobTimes: JobTime[];
}
export interface ParentProps {
    title: string;
    input: {
        location: {
            label: string;
            placeholder: string;
        }
        distance: {
            label: string;
            placeholder: string;
        }
        jobTime: {
            label: string;
            placeholder: string;
        }
        search: {
            label: string;
            placeholder: string;
        }
    };
    buttonProps: ButtonProps;
    instruction: string;
    instructionOnSearchPage: string;

    searchParams: any;
    onSearch: (searchParams: any) => void;
    children?: ReactNode;
    tooltipOffset?: Object;
}

Component.defaultProps = {
    title: null,
    children: null,
};

export default Component;