import { Props as ButtonProps } from 'components/layout/Button';
import { FilterValues } from 'components/modules/public/layout/forms/SearchForm';
import { FilterOption } from 'components/modules/public/layout/forms/SearchForm/blocks/FilterTabs';

import Component from './component';

export interface Props {
    list: any[];
    buttonProps: {
        search: ButtonProps;
        clear: ButtonProps;
    };
    onChange: (key: string, value: string, clear?: boolean) => any;
    onSearch: () => any;
    values: any;
    currentFilterOption: FilterOption;
}

Component.defaultProps = {};

export default Component;