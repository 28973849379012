import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.div`
    .expandable-menu {
        position: absolute;
        width: 100%;
        top: auto;
        left: 0;
        padding: 1.5em;
        background-color: #FFFFFF;
        border: .1em solid #000000;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        z-index: 98;

        .menu-header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .clear-control {
                .button {
                    .button-content {
                        font-weight: 300;
                    }
                }
            }
        }

        .layout-forms-input {
            width: 30%;
            padding-top: 1em;

            .layout-forms-input-wrapper {
                .internal-wrapper {
                    .input-decorator {
                        color: #AFAEAD;
                        cursor: default;
                        text-decoration: none;
                    }
                }
            }
        }

        .menu-elements {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            padding: 2em 0;

            .element {
                cursor: pointer;
                padding-top: .5em;
            }
        }

        .link {
            align-self: flex-end;
            color: #000000;
        }

    }

    @media all and (max-width: ${vars.mobileL}) {
        .expandable-menu {
            .layout-forms-input {
                width: 100%;
            }
            .menu-elements {
                overflow: auto;
                grid-template-columns: repeat(4, 80%);
            }
        }
    }
`;