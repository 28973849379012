import { ChangeEvent, FunctionComponent } from 'react';
import classnames from 'classnames';

import Button from 'components/layout/Button';
import Checkbox from 'components/layout/forms/Checkbox';
import Input, { InputStyles } from 'components/layout/forms/Input';
import { FilterOptions } from 'components/modules/public/layout/forms/SearchForm';

import { Props } from './index';
import StyledComponent from './styles';

const PublicLayoutFormsSearchFormBlocksExpandableMenu: FunctionComponent<Props> = ({
    list,
    buttonProps,
    onChange,
    onSearch,
    values,
    currentFilterOption,
}) => {
    return (
        <StyledComponent
            className={classnames(
                'public-layout-forms-search-form-blocks-expandable-menu',
            )}
        >
            <div className="expandable-menu">
                <div className="menu-header">
                    <div
                        className="header-title"
                        dangerouslySetInnerHTML={{ __html: currentFilterOption?.title }}
                    />
                    <Button
                        className="clear-control"
                        onClick={() => onChange(currentFilterOption?.slug, null, true)}
                        {...buttonProps?.clear}
                    />
                </div>
                {currentFilterOption?.slug !== FilterOptions.JobSalaryFrom && (
                    <div className="menu-elements">
                        {Array.isArray(list) && list
                            .map( (element, index) => (
                                <div
                                    className="element"
                                    key={index}
                                >
                                    <Checkbox
                                        name={element.slug}
                                        label={element.name}
                                        onChange={() => onChange(currentFilterOption?.slug, element.slug)}
                                        value={values[currentFilterOption?.slug]?.getValue().includes(element.slug)}
                                    />
                                </div>
                            ))}
                    </div>
                )}
                {currentFilterOption?.slug === FilterOptions.JobSalaryFrom && (
                    <Input
                        name="salaryFrom"
                        type="number"
                        style={InputStyles.Primary}
                        placeholder="Kwota wynagrodzenia brutto"
                        onChange={(event: ChangeEvent<HTMLInputElement>) => onChange('salary', event.target.value)}
                        value={values?.salary.getValue() }
                        decoratorRight={{
                            children: (
                                <div className="currency">
                                    ZŁ
                                </div>
                            ),
                        }}
                    />
                )}
                <div className="menu-control">
                    <Button
                        className="search-control"
                        onClick={onSearch}
                        {...buttonProps?.search}
                    />
                </div>
            </div>
        </StyledComponent>
    );
};

export default PublicLayoutFormsSearchFormBlocksExpandableMenu;