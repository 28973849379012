import { connect, FormikContextType } from 'formik';

import { Option } from 'types/options';

import {
    InputDecorator,
    Props as InputWrapperProps,
    ValidationActions,
} from 'components/layout/forms/InputWrapper';

import Component from './component';

export interface Props extends InputWrapperProps {
    formik?: FormikContextType<any>;
    name: string;
    placeholder?: string;
    value?: any;
    onChange: (option: Option<any>) => any;
    disabled?: boolean;
    style?: InputStyles;
    styles?: object;
    options: Option<any>[];
    error?: string;
    validationAction?: ValidationActions
    decoratorLeft?: InputDecorator;
    decoratorRight?: InputDecorator;
    inputProps?: any;
}

export enum InputStyles {
    Primary = 'primary',
    Secondary = 'secondary',
}

Component.defaultProps = {
    placeholder: null,
    value: '',
    style: InputStyles.Primary,
    styles: {},
    error: null,
    disabled: false,
    validationAction: ValidationActions.Decorator,
    decoratorLeft: null,
    decoratorRight: null,
    inputProps: {},
};

export default connect(Component);