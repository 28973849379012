import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.div`
    .filter-options {
        width: 100%;

        .filter-header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .header-title {
                font-weight: 700;
                color: #FFFFFF;
            }

            .layout-forms-checkbox {
                width: auto;

                .layout-forms-input-wrapper {
                    margin-bottom: 0;
                    .internal-wrapper {
                        .input-body {
                            .label {
                                color: #FFFFFF;
                                font-size: 1em;
                            }
                        }
                    }
                }
            }
        }

        .options {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-top: 1em;

            .option-container {
                display: flex;
                justify-content: space-between;
                align-items: center;

                border: .1em solid #FFFFFF;
                border-radius: .3em;
                padding: .5em 1em;
                margin-right: 1em;
                min-height: 4.5em;
                cursor: pointer;

                .option {
                    color: #FFFFFF;
                    padding-right: 1em;
                    font-size: .9em;
                }

                .icon-container {
                    width: 1em;
                    height: 1em;
                    position: relative;

                    .icon {
                        position: absolute;
                    }

                    .icon-hover {
                        position: absolute;
                        display: none;
                    }
                }

                &:hover, &.active {
                    background-color: #10F5B0;
                    border: .1em solid #10F5B0;

                    .icon {
                        display: none;
                    }
                    .icon-hover {
                        display: block;
                    }

                    .option {
                        color: #000000;
                }
            }

            .title {
                text-decoration: none;
                font-weight: 700;
                cursor: unset;
            }
        }
    }
    }
    @media all and (max-width: ${vars.mobileL}) {
        .filter-options {
            padding-top: 1.5em;

            .options {
                flex-wrap: wrap;

                .option-container {
                    width: 100%;
                    margin-top: 1em;
                    margin-right: 0;
                }
            }

            .layout-forms-checkbox {
                display: none;
            }
        }
    }
`;