import { FunctionComponent, useState } from 'react';
import classnames from 'classnames';

import ExpandableMenuBlock from './blocks/ExpandableMenu';
import FilterTabsBlock, { FilterOption } from './blocks/FilterTabs';
import MainBlock from './blocks/Main';
import { FilterOptions, Props } from './index';
import StyledComponent from './styles';

const PublicLayoutFormsSearchForm: FunctionComponent<Props> = ({
    jobCategories,
    jobAgreements,
    jobModes,
    jobExperiences,
    jobTimes,
    jobLanguages,
    mainBlockProps,
    filterTabsBlockProps,
    expandableMenuBlockProps,
    onSearch,

    searchParams,
    onChangeSearchParam,
    onChangeSearchParams,

    tooltipOffset,
}) => {
    const [currentFilterOption, setCurrentFilterOption]: [FilterOption, Function] = useState(null);

    const getList = () => {
        switch (currentFilterOption.slug) {
            case FilterOptions.JobCategories:
                return jobCategories;
            case FilterOptions.JobAgreements:
                return jobAgreements;
            case FilterOptions.JobModes:
                return jobModes;
            case FilterOptions.JobExperiences:
                return jobExperiences;
            case FilterOptions.JobLanguages:
                return jobLanguages;
        }
    };

    return (
        <StyledComponent
            className={classnames(
                'public-layout-forms-search-form',
            )}
        >
            <MainBlock

                searchParams={searchParams}

                onSearch={(searchParams) => {
                    setCurrentFilterOption(null);
                    onChangeSearchParams(searchParams);
                }}

                jobTimes={jobTimes}
                {...mainBlockProps}
                tooltipOffset={tooltipOffset}
            >
                {filterTabsBlockProps?.filterOptions && (
                    <FilterTabsBlock
                        onChangeSearchParam={onChangeSearchParam}
                        currentFilterOption={currentFilterOption}
                        setCurrentFilterOption={(nextFilterOption) => {
                            if(nextFilterOption.slug === currentFilterOption?.slug) {
                                setCurrentFilterOption(null);
                            } else {
                                setCurrentFilterOption(nextFilterOption);
                            }
                        }}
                        searchParams={searchParams}
                        {...filterTabsBlockProps}
                    />
                )}
            </MainBlock>

            {currentFilterOption && (
                <ExpandableMenuBlock
                    list={getList()}
                    onChange={onChangeSearchParam}
                    onSearch={() => {
                        setCurrentFilterOption(null);
                        onSearch();
                    }}
                    values={searchParams}
                    currentFilterOption={currentFilterOption}
                    {...expandableMenuBlockProps}
                />
            )}
        </StyledComponent>
    );
};

export default PublicLayoutFormsSearchForm;