import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.div`
	width: 100%;
	padding: 1.4em 3em 1em;
	background-color: #000000;
	border-radius: 0.3em 0.3em 0 0;
	position: relative;

	.title-row {
		.buttons {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.instructionTip {
			position: absolute;
			bottom: 13.2em;
			right: 3.3em;
			background-color: ${vars.colorBackground};
			padding: 1.5em;
			border-radius: 0.5em;
			font-size: 0.9em;
			z-index: 10;
			width: 30em;
			transition: opacity 1s ease-in-out;

			.instructionTip-text {
				display: flex;
				flex-flow: column wrap;
				gap: 0.3em;

				h2 {
					margin: 0 0 0.5em 0;
				}
			}

			.close-button {
				position: absolute;
				right: 0;
				top: 1em;
				height: 1em;

				.button {
					border: 0;
					.button-icon-image {
						width: 2em;
						height: 2em;
					}
				}
			}

			&.onSearchPage {
				padding: 1em;
				bottom: 19em;
				right: 3.3em;
				background-color: ${vars.colorBackground};
				border: 0.01em solid ${vars.colorBackgroundContrastBorderDarker};
			}
		}

		.form-title {
			font-size: 1.1em;
			font-weight: 700;
			color: #ffffff;
		}

		.title-button-wrapper {
			.layout-button {
				.button {
					border: 0.05em solid #fff;
					.button-content {
						color: #ffffff;
						font-weight: 300;
						font-size: 1.1em;
					}
				}
			}
		}
	}

	.form-input-row {
		width: 100%;
		display: grid;
		grid-template-columns: 80% 20%;
		margin-top: 1.4em;
		background-color: #ffffff;
		border-radius: 0.3em;

		.row-inputs {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.layout-forms-input, .layout-forms-select, .layout-forms-async-select {
				.layout-forms-input-wrapper {
					position: relative;

					.label-wrapper {
						position: absolute;
						top: -50%;
						transform: translateY(50%);

						.label-body {
							.layout-forms-input-label {
								color: #ffffff;
							}
						}
					}
				}
			}

			.layout-forms-async-select,
			.layout-forms-select {
				.layout-forms-input-wrapper {
					.internal-wrapper {
						.input-body {
							[class$='container'] {
								[class$='menu'] {
									background-color: rgba(255, 255, 255, 1);
								}
							}
						}
					}
				}
			}

			.name-jobTimeSlug {
				max-width: 14em;
			}

			> * {
				border-right: 0.1em solid #000000;

				&:first-of-type {
					.internal-wrapper {
						.input-body {
							input {
								border-radius: 0.3em 0 0 0.3em;
							}
						}
					}
				}
			}
		}

		.layout-button {
			margin-left: -0.5em;

			.button {
				height: 100%;
				width: 100%;
				padding: 0;
			}
		}
	}

	.button-form-footer {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-top: 0.8em;

		.layout-button {
			.button {
				font-weight: 300;
				font-size: 0.8em;

				&:hover {
					background-color: #000;

					.button-content {
						text-decoration: underline;
					}
				}
			}
		}
	}

	.children {
		padding-top: 1.5em;
	}

	@media all and (max-width: ${vars.tabletS}) {
		text-align: center;
		padding: 0 2em;

		.title-row {
			width: 100%;
			padding-top: 2.4em;
			font-size: 1.4em;
			flex-flow: column wrap;

			.instructionTip {
				font-size: 0.7em;
				background-color: ${vars.colorBackground};
				border: 0.01em solid ${vars.colorBackgroundContrastBorderDarker};
				max-width: 90%;
				bottom: 28em;
				right: 2em;

				&.onSearchPage {
					position: relative;
					inset: 0;
					font-size: 0.8em;
					width: 100%;
					max-width: 100%;
					text-align: left;
				}
			}

			.title-button-wrapper {
				.layout-button {
					.button {
						border: none;
						padding: 0;
						.button-content {
							display: none;
						}
					}
				}
			}
		}

		.form-input-row {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
			background-color: transparent;

			.row-inputs {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				align-items: center;
				.public-module-cities-select {
					&:not(:last-child) {
						margin-bottom: 2em;
					}
				}
				[class ^="layout-forms"] {
					&:not(:last-child) {
						margin-bottom: 2em;
					}
				}
				.layout-forms-input {
					.layout-forms-input-wrapper {
						.internal-wrapper {
							.input-body {
								input {
									border-radius: 0.3em;
									font-size: 125%;
								}
							}
						}
					}
				}

				.layout-forms-async-select,
				.layout-forms-select {
					.layout-forms-input-wrapper {
						.internal-wrapper {
							.input-body {
								[class$='container'] {
									[class$='control'] {
										border-radius: 0.3em;
										font-size: 120%;

										[class$='ValueContainer'] {
											padding: 0 1em;
										}
									}
								}
							}
						}
					}
				}

				.name-jobTimeSlug {
					max-width: unset;
				}
			}

			.layout-button {
				margin-left: 0;
				padding-top: 1.8em;
				width: 60%;

				.button {
					padding: 1.2em 2.5em;

					.button-content {
						font-size: 150%;
					}
				}
			}
		}

		.link {
			padding-top: 1.5em;
			text-align: center;
		}

		.button-form-footer {
			justify-content: center;
			padding-bottom: 1.4em;
			font-size: 1.6em;
		}

		.children {
			padding-top: 0;
			padding-bottom: 2em;
		}
	}

	@media all and (max-width: ${vars.mobileM}) {
		.title-row {
			.title-button-wrapper {
				.layout-tooltip {
					.react-tooltip {
						.tooltip-text {
							.tooltip-steps {
								padding-top: 1em;
								max-width: 24em;
								font-size: 90%;
							}
						}
					}
				}
			}
		}

		.form-input-row {
			.row-inputs {
				.layout-forms-input {
					.layout-forms-input-wrapper {
						.label-wrapper {
							display: none;
						}
						.internal-wrapper {
							.input-body {
								input {
									font-size: 100%;
								}
							}
						}
					}
				}

				.layout-forms-async-select,
				.layout-forms-select {
					.layout-forms-input-wrapper {
						.internal-wrapper {
							.input-body {
								[class$='container'] {
									[class$='control'] {
										font-size: 145%;
									}
								}
							}
						}
					}
				}

				.name-jobTimeSlug {
					max-width: unset;
				}
			}

			.layout-button {
				width: 70%;
			}
		}

		#tootltip,
		.__react_component_tooltip.show,
		.__react_component_tooltip {
			left: 60px !important;
			top: 98px !important;
		}
	}
`;
