import { ReactNode } from 'react';
import { Settings } from 'react-slick';

import Component from './component';

export interface Props {
    children?: ReactNode
    sliderProps?: Settings;
    slideTitle?: string;
    activeSlideIndex?: number;
    reference?:any;
}

Component.defaultProps = {
    children: null,
    sliderProps: {},
    slideTitle: null,
    activeSlideIndex: null,
};

export default Component;