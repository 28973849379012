import styled from '@emotion/styled';

import variables from 'theme/styles/vars';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default styled.div`
	.slick-slider {
		position: relative;
		z-index: 9;

		.slick-list {
			overflow: inherit;
			overflow-x: clip;

			.slick-track {
				display: flex;
				justify-content: center;
				align-items: center;

				.slick-slide {
					margin: 0 0.5em;
				}
			}
		}

		.slick-arrow {
			z-index: 999;
			width: 50px;
			height: 50px;
			display: flex !important;
			justify-content: center;
			align-items: center;
			&.slick-prev {
				transition: transform 0.3s ease-in-out;
				left: -4%;

				&:before {
					content: '<';
					color: #000000;
					font-size: 40px;
				}
				&:hover {
					transform: translate(-20%, -50%);
				}
			}
			&.slick-next {
				transition: transform 0.3s ease-in-out;
				right: -4%;

				&:before {
					content: '>';
					color: #000000;
					font-size: 40px;
				}
				&:hover {
					transform: translate(20%, -50%);
				}
			}
		}

		.slick-dots {
			display: flex !important;
			justify-content: center;
			align-items: center;
			bottom: -3em;

			li {
				max-width: 2.5em;
				width: auto;
				margin: 0 0.13em;

				.icon {
					filter: invert(92%) sepia(0%) saturate(1079%) hue-rotate(151deg)
						brightness(79%) contrast(81%);
				}

				&.slick-active {
					.icon {
						filter: invert(0%) sepia(83%) saturate(7432%) hue-rotate(15deg)
							brightness(89%) contrast(102%);
					}
				}
			}
		}
	}
	&.safari-view {
		.slick-list {
			padding: 2em 0;
			overflow: hidden;
		}
	}

	.slick-prev::before,
	.slick-next::before {
		display: none;
	}

	@media all and (max-width: ${variables.mobileM}) {
		.slick-slider {
			.slick-list {
				.slick-track {
					.slick-slide {
						opacity: 0.3;
					}

					.slick-active {
						opacity: 1;
					}
				}
			}

			.slick-arrow {
				&.slick-prev {
					left: 0px;
				}
				&.slick-next {
					right: 0px;
				}
			}

			.slick-dots {
				li {
					width: 2.5em;
					margin: 0.1em;
				}
			}
		}
		&.safari-view {
			.slick-list {
				padding: 1em 50px 1em 50px !important;
				overflow: hidden;
			}
		}
	}
`;
